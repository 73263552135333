import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import ProTip from "./ProTip";

import Grid from "@mui/material/Grid"; // Grid version 1

import CC from "./images/ccc.jpg";
import Oak from "./images/oakland2.jpg";
import bacs from "./images/bacs.png";
import allhome from "./images/allhome.png";
import kph from "./images/kph.png";

import "./fonts.css";

function Copyright() {
	return (
		<Typography variant="body2" color="text.secondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href="https://bayareacs.org/">
				Bay Area Community Services
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

export default function App() {
	return (
		<Grid
			container
			spacing={1}
			justifyContent={"center"}
			alignItems={"center"}
			textAlign={"center"}
			// padding={10}
			width={"100%"}
			marginTop={10}
			style={{ width: "80%", marginLeft: "auto", marginRight: "auto", paddingBottom: 20 }}
		>
			<Grid
				item
				xs={12}
				padding={1}
				paddingBottom={0}
				style={{ fontFamily: "Archer", fontSize: "2rem", color: "#3f51b5" }}
			>
				<Box>
					<img src={kph} style={{ height: 125 }} />
				</Box>
				Keep People Housed
			</Grid>
			<Grid
				item
				xs={12}
				paddingBottom={4}
				paddingTop={0}
				marginTop={-2}
				style={{ fontFamily: "Archer", fontSize: "1.5rem", color: "#f26522" }}
			>
				Bay Area Regional Homelessness Prevention
			</Grid>
			<Grid item xs={12} paddingBottom={5} style={{ fontFamily: "MuseoSans", fontSize: "1rem" }}>
				Welcome to the Keep People Housed Homelessness Prevention Platform! Click the images below
				to access the applications for region-specific Keep People Housed assistance. KPH may be
				able to help eligible applicants who have past-due rent, or who need help paying the costs
				of moving into a new unit they have already identified and plan to lease. Applicants are now
				eligible for one-time assistance.
			</Grid>

			<Grid
				item
				xs={12}
				md={6}
				style={{ fontFamily: "MuseoSans", fontSize: "1.2rem", fontWeight: 600 }}
			>
				<center>
					<Box>Click below if you are applying in Oakland, CA</Box>
					<Box>
						<Link href={"https://oak.keeppeoplehoused.org/"}>
							<img src={Oak} style={{ maxWidth: "100%", maxHeight: 300 }} />
						</Link>
					</Box>
				</center>
			</Grid>

			<Grid
				item
				xs={12}
				md={6}
				style={{ fontFamily: "MuseoSans", fontSize: "1.2rem", fontWeight: 600 }}
			>
				<center>
					<Box>Click below if you are applying in Contra Costa County</Box>
					{/* <Box style={{ color: "red" }}>Available on 12/4/23</Box> */}
					<Box>
						<Link href={"https://ccc.keeppeoplehoused.org/"}>
							<img src={CC} style={{ maxWidth: "100%", maxHeight: 300 }} />
						</Link>
					</Box>
				</center>
			</Grid>

			<Grid item xs={12} md={6} style={{ paddingTop: 50 }}>
				<center>
					<Box>
						<Link href={"https://bayareacs.org"}>
							<img
								height="52px"
								align={"right"}
								pointerEvents="none"
								src={bacs}
								className="alignMobile"
								style={{ float: "right", marginRight: 25 }}
							/>
						</Link>
					</Box>
				</center>
			</Grid>

			<Grid item xs={12} md={6} style={{ paddingTop: 50 }}>
				<center>
					<Box style={{ textAlign: "center" }}>
						<Link href={"#"}>
							<img
								height="52px"
								pointerEvents="none"
								src={allhome}
								className={"alignMobile"}
								style={{ float: "left", marginLeft: 25, textAlign: "center" }}
							/>
						</Link>
					</Box>
				</center>
			</Grid>

			<Grid item xs={12} fontSize={".75rem"}>
				&copy; Copyright {new Date().getFullYear()}, Bay Area Community Services.
			</Grid>
		</Grid>
		// {/* <ProTip /> */}
		// <Copyright />
	);
}
